body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
     min-width: 1500px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
               monospace;
}

.showimpressum {
    text-align: left !important;
    display: left !important;
    border: none;
    outline: none;
    background: none;
    font-weight: bold;
    text-decoration: underline;
    color: #303030 !important;
    margin-bottom: 1000px;
    z-index: 2;
}

.impressum {
  height:70vh;
  overflow: scroll;
  padding-left: 20px;
}

.PC-table{
  border-collapse: collapse;
    width: 80%;
    margin-bottom: 10px;
      }

.PC-table thead tr {
    background-color: #49a1ae;
    color: #ffffff;
    text-align: center;
}

.PC-table th,
.PC-table td {
    padding: 12px 10px;
}

.PC-table  tr {
    border-bottom: 1px solid #dddddd;
}

.PC-table  tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.PC-table  tr:last-of-type {
    border-bottom: 2px solid #49a1ae;
}

.expert{
  width: 90%;
}

.content {
  float:left;
  width:80%;
  height: 100vh;
  overflow: hidden;
}

.left{
   float:left;
   width:10%;
   height:90vh;
}

.right{
  float:right;
  width:10%;
  height:90vh;
}

.slick-dots  {
  width: 50% !important;
  position: inherit !important;
  margin : auto !important;
  bottom: -60px !important;
  z-index: 1
}

.button {
   display: inline-block;
   position: absolute;
}

.button.next {
    float: right;
    top: 50%;
    margin: -60px 0 0 35px;
    height: 120px;
    width: 120px;
}

.game {
    height: 60px;
    width: 60px;
    margin: 0 auto;
}

.gameEnd {
    display: inline-block;
}

.gameInfo {
    float: left;
    width: 350px;
    display: inline-grid;
    text-align: left;
}

.button.previous {
    float: left;
    top: 50%;
    margin: -60px 0 0 35px;
    height: 120px;
    width: 120px;
}

.slick-slider {
  margin-left: 30px;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  }

.slick-slide.slick-active.slick-current {
  display: flex !important;
  height: 70vh !important;
  overflow-y: scroll !important;
}

.slick-dots{
  position: absolute !important;
  width: 100% !important;
  padding: 0px !important;
}

/* For changing the color of the slider dots */
.slick-dots li button:before {
    color: #49a1ae !important;
    font-size: 15px !important;
  }

.slider{
  position: relative;
  bottom: 10%;
  padding: 0px !important;
  min-width: 1000px;
}

h2 {
  font-family: Helvetica, sans-serif !important;
  color: #303030 !important;
  margin-left: 15px !important;
}

.slick-prev {
  left: 5%;
}

p, h3{
  font-family: Helvetica, sans-serif !important;
  color: #303030 !important;
}

.gameChart{
  float: left;
  display: flex;
  width: 800px;
}

@media (orientation: landscape) and (max-width: 768px){
  .slick-slide.slick-active.slick-current {
    height: 30vh !important;
  }

  .impressum {
    height: 30vh!important;
  }
}

@media (pointer:coarse) and (max-width: 768px){
  .gameChart{
    width: 90vw !important;
  }
}
@media (orientation: landscape) and (pointer:coarse) {
  .gameChart{
    width: 70vw !important;
  }
}

@media (pointer:coarse) {
    body {

      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
                   'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
                   'Helvetica Neue', sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    p, h3 {
        font-family: Helvetica, sans-serif !important;
        word-wrap: normal;
        color: #000000 !important;
        width: 95%;
    }

    .content {
        position: fixed;
        margin: 0 auto;
        width: 100%;
    }

    .slick-slide.slick-active.slick-current {
      display: flex !important;
      overflow-y: scroll !important;
    }

    .slick-dots{
      position: absolute !important;
      width: 100% !important;
      padding-bottom: 25px !important;
    }

    .MuiSlider-root{
        width: 95% !important;
    }

    .MuiSlider-thumb{
      margin: 100px;
    }

    .gameChart{
      width: 95vw;
    }
}
